import { InjectionToken } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export const SsWsConfig = new InjectionToken<SsWebSocketsConfig>('SsWsConfig');

export interface SsWebSocketsConfig {
  url: string;
  user: ReplaySubject<string>;
  timestamp: string;
  token: string;
}

export enum SsWebSocketsPublicChanel {
  LATEST_WINNERS = 'public:wins'
}

export enum SsWebSocketsPrivateChanel {
  COMP_POINTS = 'comps_award',
  BONUSES_CHANGED = 'bonuses_changes',
  FREESPINS_CHANGED = 'freespins_changes',
  LOOTBOXES_CHANGED = 'lootboxes_changes',
  PAYMENTS_CHANGED = 'payments_changes',
  GAME_LIMITS = 'game_limits',
  TOURNAMENTS_STATUSES = 'tournaments_statuses',
  TOURNAMENT_START = 'tournament_notifications',
  GROUPS_UPDATES = 'groups_updates',
  APP_INSTALL = 'install_app',
  PROMO = 'promo',
  USER_BALANCE = 'balance'
}
